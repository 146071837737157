import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientCreateFormState } from './client-create-form.state';

export const clientCreateFeatureKey = 'crmClientCreate';

export const selectClientCreateFeature =
  createFeatureSelector<ClientCreateFormState>(clientCreateFeatureKey);

export const selectCurrentStep = createSelector(
  selectClientCreateFeature,
  (state) => state.currentStep,
);
