import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';
import { zipCodeValidator } from 'src/app/shared/validators/zip-code.validator';
import { ClientCreateForm } from './client-create-form.interface';
import { Zip } from 'src/app/core/models/app.models';

@Injectable({ providedIn: 'root' })
export class ClientCreateFormService {
  #fb = inject(NonNullableFormBuilder);

  public readonly form: FormGroup<ClientCreateForm> = this.#fb.group({
    person: this.#fb.group({
      firstName: this.#fb.control('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      lastName: this.#fb.control('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      email: this.#fb.control('', [Validators.required, Validators.email]),
      phoneNumber: this.#fb.control('', [
        Validators.maxLength(15),
        phoneNumberValidator(),
      ]),
    }),
    address: this.#fb.group({
      street: this.#fb.control('', [Validators.maxLength(255)]),
      plz: this.#fb.control<Zip | null>(null, [Validators.required]),
    }),
  });
}
