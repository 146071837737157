import { createAction, props } from '@ngrx/store';

export const resetForm = createAction('[CRM Create Client]');
export const tryNextStep = createAction('[CRM Create Client] Try go next');
export const tryGoToStep = createAction(
  '[CRM Create Client] Try go to step',
  props<{ step: number }>(),
);

export const changeStep = createAction(
  '[CRM Create Client] Change step',
  props<{ step: number }>(),
);

export const createClientSuccess = createAction(
  '[CRM Create Client] Create Client success',
);

export const save = createAction('[CRM Create Client] Save new client');
export const back = createAction('[CRM Create Client] Back Clicked');
